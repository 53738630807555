import {
  PlatformType
} from '@invideoio/web-shared/constants/common';
import { LOCAL_STORAGE } from '@invideoio/web-shared/utils/utils.constant';
import { CMSDimensionType } from '../constants/common';
import {
  SingleSlideProps,
  TemplateCardType,
  TemplateType
} from '../types';

declare global {
  interface Window {
    LiveChatWidget: any;
  }
}

export const getImageKitUrl = (
  url: string,
  transformations?: {
    width?: number;
    quality?: number;
    grayscale?: boolean;
    blur?: number;
  },
): string => {
  let result = process.env.NEXT_PUBLIC_IMAGEKIT_URL_ENDPOINT ?? '';
  if (transformations && Object.keys(transformations).length > 0) {
    const { width, quality, grayscale, blur } = transformations;
    const tranformationsList: Array<string> = [];
    if (width) tranformationsList.push(`w-${width}`);
    if (quality) tranformationsList.push(`q-${clamp(quality, 0, 100)}`);
    if (grayscale) tranformationsList.push(`e-grayscale`);
    if (blur) tranformationsList.push(`bl-${clamp(blur, 0, 100)}`);
    result += `/tr:${tranformationsList.join(',')}`;
  }
  result += new URL(url).pathname;
  return result;
};

export const constructSourceSet = (
  options: Array<{ url: string; intrinsicWidth: number }>,
): string => {
  const srcSet = options
    .map(it => `${it.url} ${it.intrinsicWidth}w`)
    .join(', ');

  return srcSet;
};

export const constructSourceSetSizes = (
  options: Array<{ query: string; size: string }>,
): string => {
  return options.map(it => `${it.query} ${it.size}`.trim()).join(', ');
};

export const extractTemplateIds = (
  templateSlide1: SingleSlideProps,
  templateSlide2: SingleSlideProps,
  templateSlide3: SingleSlideProps,
  templateSlide4: SingleSlideProps,
): number[] => {
  return [
    ...templateSlide1.column_1.map(e => e.templateId),
    ...templateSlide1.column_2.map(e => e.templateId),
    ...templateSlide1.column_3.map(e => e.templateId),
    ...templateSlide1.column_4.map(e => e.templateId),
    ...templateSlide2.column_1.map(e => e.templateId),
    ...templateSlide2.column_2.map(e => e.templateId),
    ...templateSlide2.column_3.map(e => e.templateId),
    ...templateSlide2.column_4.map(e => e.templateId),
    ...templateSlide3.column_1.map(e => e.templateId),
    ...templateSlide3.column_2.map(e => e.templateId),
    ...templateSlide3.column_3.map(e => e.templateId),
    ...templateSlide3.column_4.map(e => e.templateId),
    ...templateSlide4.column_1.map(e => e.templateId),
    ...templateSlide4.column_2.map(e => e.templateId),
    ...templateSlide4.column_3.map(e => e.templateId),
    ...templateSlide4.column_4.map(e => e.templateId),
  ];
};

export const extractTemplateDimensions = (
  templateSlide1: SingleSlideProps,
  templateSlide2: SingleSlideProps,
  templateSlide3: SingleSlideProps,
  templateSlide4: SingleSlideProps,
): CMSDimensionType[] => {
  return [
    ...templateSlide1.column_1.map(e => e.dimension),
    ...templateSlide1.column_2.map(e => e.dimension),
    ...templateSlide1.column_3.map(e => e.dimension),
    ...templateSlide1.column_4.map(e => e.dimension),
    ...templateSlide2.column_1.map(e => e.dimension),
    ...templateSlide2.column_2.map(e => e.dimension),
    ...templateSlide2.column_3.map(e => e.dimension),
    ...templateSlide2.column_4.map(e => e.dimension),
    ...templateSlide3.column_1.map(e => e.dimension),
    ...templateSlide3.column_2.map(e => e.dimension),
    ...templateSlide3.column_3.map(e => e.dimension),
    ...templateSlide3.column_4.map(e => e.dimension),
    ...templateSlide4.column_1.map(e => e.dimension),
    ...templateSlide4.column_2.map(e => e.dimension),
    ...templateSlide4.column_3.map(e => e.dimension),
    ...templateSlide4.column_4.map(e => e.dimension),
  ];
};

const attachColumn = (
  idx: number,
  column: TemplateCardType[],
  images: TemplateType[],
) => {
  const updatedColumn = column.map(e => {
    idx++;
    return {
      ...e,
      thumbnailURL: images[idx].thumbnailURL,
      previewURL: images[idx].previewURL,
    };
  });
  return {
    column: updatedColumn,
    idx,
  };
};

const attachSingleSlide = (
  idx: number,
  slide: SingleSlideProps,
  images: TemplateType[],
) => {
  const { column: column_1, idx: index1 } = attachColumn(
    idx,
    slide.column_1,
    images,
  );
  const { column: column_2, idx: index2 } = attachColumn(
    index1,
    slide.column_2,
    images,
  );
  const { column: column_3, idx: index3 } = attachColumn(
    index2,
    slide.column_3,
    images,
  );
  const { column: column_4, idx: index4 } = attachColumn(
    index3,
    slide.column_4,
    images,
  );
  return {
    slide: {
      column_1,
      column_2,
      column_3,
      column_4,
    },
    idx: index4,
  };
};

export const attachTemplates = (
  templateSlide1: SingleSlideProps,
  templateSlide2: SingleSlideProps,
  templateSlide3: SingleSlideProps,
  templateSlide4: SingleSlideProps,
  images: TemplateType[],
) => {
  let idx = -1;
  const { slide: templateSlide_1, idx: index1 } = attachSingleSlide(
    idx,
    templateSlide1,
    images,
  );
  const { slide: templateSlide_2, idx: index2 } = attachSingleSlide(
    index1,
    templateSlide2,
    images,
  );
  const { slide: templateSlide_3, idx: index3 } = attachSingleSlide(
    index2,
    templateSlide3,
    images,
  );
  const { slide: templateSlide_4, idx: index4 } = attachSingleSlide(
    index3,
    templateSlide4,
    images,
  );
  return {
    templateSlide_1,
    templateSlide_2,
    templateSlide_3,
    templateSlide_4,
  };
};

export const generateRedirectionURLwithHost = (url: string, userData: any) => {
  const appName = process.env.NEXT_PUBLIC_APP_NAME || 'invideo';
  const authToken = localStorage.getItem('authToken') || '';

  let host = process.env.NEXT_PUBLIC_STUDIO_HOST || 'studio.invideo.io';

  if (authToken) {
    if (userData && appName === 'kizoa' && !url.includes('kizoa.com')) {
      host = process.env.NEXT_PUBLIC_STUDIO_HOST || 'kizoa.com';
      url +=
        `${url.includes('?') ? '&' : '?'}token=` +
        window.btoa(JSON.stringify({ authToken, ...userData }));
    } else if (userData) {
      url +=
        `${url.includes('?') ? '&' : '?'}token=` +
        window.btoa(JSON.stringify({ token: authToken, ...userData }));
    } else {
      url +=
        `${url.includes('?') ? '&' : '?'}token=` +
        window.btoa(JSON.stringify({ token: authToken }));
    }
  }

  return host + url;
};

export const generateRedirectionURLwithStudioHost = (url: string) => {
  const authToken = localStorage.getItem('authToken') || '';

  let host = process.env.NEXT_PUBLIC_STUDIO_HOST || 'studio.invideo.io';

  if (authToken) {
    url += `${url.includes('?') ? '&' : '?'}t=${authToken}`;
  }

  return host + url;
};

export const isUserLoggedIn = () => {
  const authToken = localStorage.getItem(LOCAL_STORAGE.AUTH_TOKEN);
  return (
    authToken != null && authToken !== undefined && authToken?.trim() !== ''
  );
};

export const getYoutubeEmbedLink = (url: string) => {
  const regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[7].length == 11 ? match[7] : '';
};

export const getYoutubePreviewUrl = (
  ytVideoId: string,
  platform: PlatformType,
) => {
  let qualityPath = 'hqdefault';
  switch (platform) {
    case PlatformType.Desktop:
      qualityPath = 'maxresdefault';
      break;
    default:
      qualityPath = 'hqdefault';
      break;
  }
  return `https://img.youtube.com/vi/${ytVideoId}/${qualityPath}.jpg`;
};

export const filterBlogResponseForCards = (response: Record<string, any>[]) => {
  return response.map(blog => {
    return {
      id: blog.id,
      Slug: blog.Slug,
      Title: blog.Title,
      published_at: blog.published_at,
      platform: blog.platform,
      profession: blog.profession,
      trending: blog.trending,
      categories_v_2: blog.categories_v_2,
      FeatureImage: blog.FeatureImage,
    };
  });
};

export const openLiveChat = () => {
  if (window.LiveChatWidget) {
    window.LiveChatWidget.call('maximize');
  } else {
    const liveChatElement = document.getElementById('fresh-chat-custom');
    if (liveChatElement) {
      liveChatElement.click();
    }
  }
};

const clamp = (number: number, minValue: number, maxValue: number): number =>
  Math.min(Math.max(number, minValue), maxValue);
