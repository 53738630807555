const assetsHost = `${process.env.NEXT_PUBLIC_CDN_URL}/homepage`;
const brandLogos = `${process.env.NEXT_PUBLIC_SHARED_CDN_URL}/brand-logos`;
const invideoLogos = `${process.env.NEXT_PUBLIC_SHARED_CDN_URL}/logos`;
const videoHost = `${process.env.NEXT_PUBLIC_CDN_URL}/homepage/videos`;
const posterImagesHost = `${process.env.NEXT_PUBLIC_CDN_URL}/homepage/videos/poster-images`;
const testimonialHost = `${process.env.NEXT_PUBLIC_CDN_URL}/homepage/testimonials`;
const socialIcons = `${process.env.NEXT_PUBLIC_SHARED_CDN_URL}/social-icons`;
export const baseHost = process.env.NEXT_PUBLIC_STUDIO_HOST;
export const aiBaseHost = process.env.NEXT_PUBLIC_AI_HOST;

export enum AUTH_TYPE {
  SIGNUP = 'SIGNUP',
  LOGIN = 'LOGIN',
}

export const HOMEPAGE_SEO = {
  title: 'Online Video Editor | Video Creator | InVideo',
  description:
    "InVideo's Online Video Editor Helps You Make Professional Videos From Premium Templates, Images, And Music.",
  canonicalUrl: 'https://invideo.io/',
  keywords:
    'photo,image,images,photos,video,videos,convert image to video online',
};

export const HOMEPAGE_HERO_SECTION = {
  heading: {
    plainHeading1: 'Free online video',
    plainHeading2: '',
    highlightedHeading: 'editor & maker',
  },
  sideText: {
    highlightedText: 'InVideo is a powerful, online video editor.',
    plainText1:
      ' It comes packed with 5000+ templates, iStock media, music library, filters, transitions and much more.',
    plainText2: ' No download required.',
  },
  buttonText: 'Get started - it’s Free!',
  subText: 'No credit card required',
  CTAUrl: `${baseHost}/dashboard`,
  video: {
    url: `${videoHost}/HeroVideo.mp4`,
  },
  poster: {
    desktop: {
      defaultURL: `${posterImagesHost}/HeroVideo.png`,
      webpURL: `${posterImagesHost}/HeroVideo.webp`,
    },
    mobile: {
      defaultURL: `${posterImagesHost}/HeroVideo.png`,
      webpURL: `${posterImagesHost}/HeroVideo.webp`,
    },
    tablet: {
      defaultURL: `${posterImagesHost}/HeroVideo.png`,
      webpURL: `${posterImagesHost}/HeroVideo.webp`,
    },
  },
};

export const HOMEPAGE_RATINGS = [
  {
    firstRow: {
      text: '25M',
    },
    secondRow: {
      text: 'Customers',
    },
    thirdRow: {
      text: {
        boldText: '190',
        plainText: 'countries',
      },
    },
    CTAUrl: '',
  },
  {
    firstRow: {
      image: {
        url: `${assetsHost}/rating.svg`,
        width: 108,
        height: 60,
      },
    },
    secondRow: {
      text: 'Product of the month',
    },
    thirdRow: {
      image: {
        url: `${assetsHost}/product-hunt.svg`,
        width: 110,
        height: 27,
      },
    },
    CTAUrl: 'https://www.producthunt.com/posts/invideo/reviews',
  },
  {
    firstRow: {
      text: '4-8',
    },
    secondRow: {
      rating: {
        number: 4.8,
        color: '--achievement-capterra',
        maskColor: '--achievement-capterra-mask',
      },
    },
    thirdRow: {
      image: {
        url: `${assetsHost}/captera.svg`,
        width: 110,
        height: 27,
      },
    },
    CTAUrl: 'https://www.capterra.com/p/180680/InVideo/',
  },
  {
    firstRow: {
      text: '4-6',
    },
    secondRow: {
      rating: {
        number: 4.6,
        color: '--achievement-trustpilot',
        maskColor: '--achievement-trustpilot-mask',
      },
    },
    thirdRow: {
      image: {
        url: `${assetsHost}/trustpilot.svg`,
        width: 110,
        height: 27,
      },
    },
    CTAUrl: 'https://www.trustpilot.com/review/invideo.io',
  },
  {
    firstRow: {
      text: '4-8',
    },
    secondRow: {
      rating: {
        number: 4.8,
        color: '--achievement-g2',
        maskColor: '--achievement-g2-mask',
      },
    },
    thirdRow: {
      image: {
        url: `${assetsHost}/grating.svg`,
        width: 34,
        height: 34,
      },
    },
    CTAUrl: 'https://www.g2.com/products/invideo/reviews',
  },
];

export const HOMEPAGE_FEATURECARDS = {
  sectionHead: 'Everything you need',
  card1: {
    heading: {
      heading1: 'Customizable templates ',
      heading2: 'for all needs',
    },
    text: 'Choose from 5000+ templates to create powerful ads, promos, YouTube videos, intros and more.',
    btnText: 'Explore templates',
    image: {
      url: `${assetsHost}/TemplatesForAll.png`,
      alt: 'image',
    },
    CTAUrl: `${baseHost}/workflow/marketing-templates`,
  },
  card2: {
    heading: {
      heading1: '8+ millions of videos, ',
      heading2: 'photos & music tracks',
    },
    video: {
      url: `${videoHost}/StockFootage2xV2.mp4`,
      poster: {
        defaultURL: `${posterImagesHost}/StockFootage.png`,
        webpURL: `${posterImagesHost}/StockFootage.webp`,
      },
    },
    brandLogos: {
      istock: `${assetsHost}/Istock-logo.svg`,
      storyblocks: `${assetsHost}/Storyblocks-logo.svg`,
      shutterstock: `${assetsHost}/Shutterstock-logo.svg`,
    },
  },
  card3: {
    desktop: {
      heading: {
        heading1: 'Powerful video ',
        heading2: 'editing features',
      },
      video: {
        url: `${videoHost}/PowerfulVideoEditing.mp4`,
        poster: {
          defaultURL: `${posterImagesHost}/PowerfulVideoEditing.png`,
          webpURL: `${posterImagesHost}/PowerfulVideoEditing.webp`,
        },
      },
      videoTimeline: [
        { start: 0, end: 2.09, name: 'Trim' },
        { start: 3.0, end: 5.14, name: 'Crop' },
        { start: 5.15, end: 8.49, name: 'Transitions' },
        { start: 8.5, end: 11.0, name: 'Text' },
        { start: 11.01, end: 15.5, name: 'Stickers' },
      ],
    },
    mobile: {
      heading: {
        heading1: 'Powerful video ',
        heading2: 'editing features',
      },
      video: {
        url: `${videoHost}/PowerfulVideoEditingMobile.mp4`,
        poster: {
          defaultURL: `${posterImagesHost}/PowerfulVideoEditingMobile.png`,
          webpURL: `${posterImagesHost}/PowerfulVideoEditingMobile.webp`,
        },
      },
      videoTimeline: [
        { start: 0, end: 2.09, name: 'Trim' },
        { start: 3.0, end: 5.14, name: 'Crop' },
        { start: 5.15, end: 8.49, name: 'Transitions' },
        { start: 8.5, end: 11.0, name: 'Text' },
        { start: 11.01, end: 15.5, name: 'Stickers' },
      ],
    },
  },
  card4: {
    desktop: {
      heading: {
        heading1: 'Edit audio',
      },
      video: {
        url: `${videoHost}/EditAudio2X.webm`,
        poster: {
          defaultURL: `${posterImagesHost}/EditAudio.png`,
          webpURL: `${posterImagesHost}/EditAudio.webp`,
        },
      },
      videoTimeline: [
        { start: 0, end: 2.3, name: 'Trim' },
        { start: 2.31, end: 5.3, name: 'Fade-in & out' },
        { start: 5.31, end: 10.1, name: 'Loop' },
        { start: 10.11, end: 15, name: 'Voiceover' },
      ],
    },
  },
  card5: {
    heading: {
      heading1: 'Brand kits',
    },
    text: 'Create multiple brand kits and apply to any video with just one click.',
    image: {
      url: `${assetsHost}/brand-kits.png`,
      alt: 'image',
    },
  },
  card6: {
    heading: {
      heading1: 'Add your team',
    },
    text: 'Create, edit, comment, and share your videos together with your team members.',
    image: {
      url: `${assetsHost}/Team.png`,
      alt: 'image',
    },
  },
  card7: {
    desktop: {
      heading: {
        heading1: '40+ fluid animations',
      },
      video: {
        url: `${videoHost}/FluidAnimations.mp4`,
        poster: {
          defaultURL: `${posterImagesHost}/FluidAnimations.png`,
          webpURL: `${posterImagesHost}/FluidAnimations.webp`,
        },
      },
      videoTimeline: [
        { start: 0, end: 2.99, name: 'Slide' },
        { start: 3, end: 5.5, name: 'Blur' },
        { start: 5.51, end: 8, name: 'Bounce' },
        { start: 8.01, end: 13.89, name: 'Spin' },
        { start: 13.9, end: 18, name: 'Disco' },
      ],
    },
  },
  card8: {
    heading: {
      heading1: 'Collaborate in real-time with multiplayer editing',
    },
    text: 'Create, edit, comment, and share your videos together with your team members.',
    cta: 'Coming soon',
    image: {
      mobile: {
        defaultUrl: `${assetsHost}/collaborate-ai-1x.png`,
        webpUrl: `${assetsHost}/collaborate-ai-1x.webp`,
      },
      tablet: {
        defaultUrl: `${assetsHost}/collaborate-ai-2x.png`,
        webpUrl: `${assetsHost}/collaborate-ai-2x.webp`,
      },
      desktop: {
        defaultUrl: `${assetsHost}/collaborate-ai-3x.png`,
        webpUrl: `${assetsHost}/collaborate-ai-3x.webp`,
      },
      alt: 'image',
    },
  },
  card9: {
    heading: {
      heading1: 'Edit audio',
    },
    image: {
      defaultUrl: `${assetsHost}/voiceover-graphic.png`,
      webpUrl: `${assetsHost}/voiceover-graphic.webp`,
      alt: 'image',
    },
  },
};

export const HOMEPAGE_BRANDS = {
  sectionHead: 'Trusted by',
  images: [
    {
      Url: `${brandLogos}/dropbox.svg`,
      Height: 34,
      Width: 172,
      alt: 'Dropbox',
    },
    {
      Url: `${brandLogos}/cnn.svg`,
      Height: 42,
      Width: 88,
      alt: 'CNN',
    },
    {
      Url: `${brandLogos}/cnbc.svg`,
      Height: 60,
      Width: 72,
      alt: 'CNBC',
    },
    {
      Url: `${brandLogos}/PandG.svg`,
      Height: 70,
      Width: 70,
      alt: 'P and G',
    },
    {
      Url: `${brandLogos}/ibm.svg`,
      Height: 44,
      Width: 110,
      alt: 'IBM',
    },
    {
      Url: `${brandLogos}/microsoft.svg`,
      Height: 38,
      Width: 175,
      alt: 'Microsoft',
    },
  ],
};

export const HOMEPAGE_TESTIMONIAL = [
  {
    image: `${testimonialHost}/AnkushC.png`,
    statement: 'Possibly the best tool for video making',
    name: 'Ankush C.',
    source: 'Capterra',
    ratings: 4.5,
  },
  {
    image: `${testimonialHost}/GayaneY.png`,
    statement: 'The best for social media',
    name: 'Gayane Y.',
    source: 'Capterra',
    ratings: 4.5,
  },
  {
    statement: 'Create video like a pro',
    name: 'Fausto P.',
    source: 'Capterra',
    ratings: 4.5,
  },
  {
    statement: 'Amazing product and world beating service',
    name: 'Jonathan C. J.',
  },
  {
    statement: 'Must-have marketing tool',
    name: 'Deb McDiarmid',
    source: 'Trustpilot',
    ratings: 4.5,
  },
  {
    image: `${testimonialHost}/FionaW.png`,
    statement: 'A Fun & Easy Video Creation Software',
    name: 'Fiona W.',
    source: 'Capterra',
    ratings: 4.5,
  },
  {
    image: `${testimonialHost}/HoangPhan.png`,
    statement: 'Video app of my dreams',
    name: 'Hoang Phan',
    source: 'Trustpilot',
    ratings: 4.5,
  },
  {
    statement: 'Finally, videos made easy with superb support.',
    name: 'Juan C.',
    source: 'Capterra',
    ratings: 4.5,
  },
  {
    statement: 'This is what video creation should be!',
    name: 'Sarah P.',
    source: 'Capterra',
    ratings: 5,
  },
  {
    statement: 'Making everyone a video producer',
    name: 'Hetvi K.',
    source: 'Capterra',
    ratings: 4.5,
  },
  {
    image: `${testimonialHost}/JasonZ.png`,
    statement: 'Top-tier video content creation tool',
    name: 'Jason Z.',
    source: 'Capterra',
    ratings: 4.5,
  },
];

export const HOMEPAGE_PRICING = {
  title: 'Pricing',
  plans: [
    {
      planName: 'Free',
      planDescription: 'For anyone who wishes to try InVideo',
      planPrice: {
        monthly: {
          currency: '$',
          price: 0,
          subText: '',
        },
        annually: {
          currency: '$',
          price: 0,
          subText: '',
        },
      },
      CTA: {
        text: 'Start now',
        backgroundColorClass: 'iv-bg-white-10',
      },
      feature: [
        {
          highlightedText: '',
          plainText: 'InVideo Watermark',
        },
        {
          highlightedText: '',
          plainText: '40min video duration',
        },
        {
          highlightedText: '',
          plainText: '3M+ standard media library',
        },
        {
          highlightedText: '',
          plainText: 'Automated text to speech',
        },
        {
          highlightedText: '',
          plainText: '5000+ video templates',
        },
      ],
      featuresCheckImage: `${assetsHost}/check-icon.svg`,
      redirection: {
        url: `${baseHost}/dashboard`,
        type: 'internal',
        modal: true,
      },
    },
    {
      planName: 'Business',
      planDescription: 'For small business, educators and influencers',
      bannerText: 'Popular',
      planPrice: {
        monthly: {
          currency: '$',
          price: 30,
          subText: '/ mo*',
          starText: '*Billed monthly until cancelled',
        },
        annually: {
          currency: '$',
          price: 15,
          subText: '/ mo*',
          starText: '*Billed $180 annually',
        },
      },
      CTA: {
        text: 'Get Business',
        backgroundColorClass: 'iv-bg-blue',
      },
      feature: [
        {
          highlightedText: 'No watermark ',
          plainText: 'on videos',
        },
        {
          highlightedText: '1M+ ',
          plainText: 'premium media library',
        },
        {
          highlightedText: '60 ',
          plainText: 'HD video exports / mo',
        },
        {
          highlightedText: '10 ',
          plainText: 'iStock media / mo',
        },
        {
          highlightedText: '20 ',
          plainText: 'remove background / mo',
        },
      ],
      appPromo: {
        firstLine: 'Get Filmr Pro ',
        secondLine: 'Mobile app',
        CTAText: 'Read more',
        backgroundGradientClass: 'iv-gradient-raspberry-deep-blue-blue',
        appImage: `${assetsHost}/filmr-mobile.png`,
      },
      featuresCheckImage: `${assetsHost}/check-icon.svg`,
      redirection: {
        url: `${baseHost}/pricing`,
        type: 'internal',
        modal: true,
      },
    },
    {
      planName: 'Unlimited',
      planDescription: 'For agencies and multiple teams',
      planPrice: {
        monthly: {
          currency: '$',
          price: 60,
          subText: '/ mo*',
          starText: '*Billed monthly until cancelled',
        },
        annually: {
          currency: '$',
          price: 30,
          subText: '/ mo*',
          starText: '*Billed $360 annually',
        },
      },
      CTA: {
        text: 'Get Unlimited',
        backgroundColorClass: 'iv-bg-violet',
      },
      feature: [
        {
          highlightedText: 'No watermark ',
          plainText: 'on videos ',
        },
        {
          highlightedText: '1M+ ',
          plainText: 'premium media library',
        },
        {
          highlightedText: 'Unlimited ',
          plainText: 'HD video exports',
        },
        {
          highlightedText: '120 ',
          plainText: 'iStock media / mo',
        },
        {
          highlightedText: '40 ',
          plainText: 'remove background / mo',
        },
      ],
      appPromo: {
        firstLine: 'Get Filmr Pro ',
        secondLine: 'Mobile app',
        CTAText: 'Read more',
        backgroundGradientClass: 'iv-gradient-raspberry-violet',
        appImage: `${assetsHost}/filmr-mobile-unlimited.png`,
      },
      featuresCheckImage: `${assetsHost}/check-icon.svg`,
      redirection: {
        url: `${baseHost}/pricing`,
        type: 'internal',
        modal: true,
      },
    },
  ],
  sectionCTA: 'Compare all features',
  CTALink: `${baseHost}/pricing#plan-heading`,
};

export const HOMEPAGE = {
  letsConnect: {
    sectionTitle: "Let's connect",
    webinar: {
      title: 'Live webinars',
      agentVideo: {
        url: `${videoHost}/CustomerSupport.mp4`,
        poster: {
          defaultURL: `${posterImagesHost}/CustomerSupport.png`,
          webpURL: `${posterImagesHost}/CustomerSupport.webp`,
        },
      },
      zoomImage: `${assetsHost}/webinarIcon.svg`,
      details:
        'Join the webinar to learn video editing from our dedicated video experts & ask them any questions!',
      CTAText: 'Pick a time slot',
      CTALink: 'https://calendly.com/d/gsj-39j-bbj',
    },
    smallCards: [
      {
        textColorClass: 'iv-text-grey-85',
        cardColorClass: 'iv-bg-black-4',
        title: 'Tutorials',
        details:
          'Master InVideo with our extensive list of more than 500 tutorials.',
        linkText: 'Go to tutorials',
        link: process.env.NEXT_PUBLIC_HELP_URL || 'https://help.invideo.io/',
        hoverStyle:
          'iv-transition-all hover:iv-text-grey-0 hover:iv-bg-grey-85',
        smallCardType: 'tutorial',
      },
      {
        textColorClass: 'iv-text-grey-0',
        cardColorClass: 'iv-bg-fb-blue',
        title: 'Join FB community',
        details: 'Interact with & learn from 23k+ creators just like you.',
        linkText: 'Connect now',
        link: 'https://www.facebook.com/groups/invideo.io/',
        linkImage: `${socialIcons}/white-fb-logo.svg`,
        hoverStyle: 'iv-transition-all hover:iv-underline',
        smallCardType: 'community',
        medium: 'FB',
      },
      {
        textColorClass: 'iv-text-grey-0',
        cardColorClass: 'iv-bg-youtube-red',
        title: 'Useful tips',
        details:
          'Push your creativity or ride on a fresh trend with Youtube tutorials.',
        linkText: 'Watch now',
        link: 'youtube',
        linkImage: `${socialIcons}/white-youtube-logo.svg`,
        hoverStyle: 'iv-transition-all hover:iv-underline',
        smallCardType: 'social',
        medium: 'YT',
      },
      {
        textColorClass: 'iv-text-grey-85',
        cardColorClass: 'iv-bg-black-4',
        title: 'Get inspired',
        details: 'Check out our Instagram for inspirations and ideas.',
        linkText: 'Follow us',
        link: 'instagram',
        linkImage: `${socialIcons}/black-instagram-logo.svg`,
        hoverStyle: 'iv-transition-all hover:iv-underline',
        smallCardType: 'social',
        medium: 'IG',
      },
    ],
    endText: 'Our support team is live 24/7 for all the questions you have',
    chatButton: 'Chat with us',
  },
  superbVideo: {
    logo: {
      url: `${invideoLogos}/invideo-logo-heart.svg`,
      width: 56,
      height: 54,
      alt: 'InVideo logo',
    },
    heading: {
      highlightedHeading: 'Superb Videos',
      plainHeading: `Let's create`,
    },
    buttonText: 'Get started - it’s free!',
    CTAUrl: `${baseHost}/dashboard`,
    AiCTAUrl: `${aiBaseHost}/signup`,
  },
  appPromo: {
    sectionHeading: 'Filmr',
    cardHeading: 'Edit on the go',
    mobileImage: `${assetsHost}/iv-app-banner-3x.png`,
    achievement: `${assetsHost}/filmr-achievements.svg`,
    downloads: {
      appDownloadsCount: '6M',
      subText: 'Downloads',
    },
    description: {
      highlightedText: 'The easiest way to make videos on your phone.',
      plainText:
        ' Use exclusive filters, effects and transitions. Add your favorite music from files or explore our Royalty Free Music.',
    },
  },
  appPromoAI: {
    sectionHeading: 'Filmr',
    cardHeading: 'Edit on the go',
    mobileImage: `${assetsHost}/phone_3x.png`,
    achievement: `${assetsHost}/filmr-achievements.svg`,
    downloads: {
      appDownloadsCount: '6M',
      subText: 'Downloads',
    },
    description: {
      highlightedText: 'The easiest way to make videos on your phone.',
      plainText:
        ' Use exclusive filters, effects and transitions. Add your favorite music from files or explore our Royalty Free Music.',
    },
  },
  workflow: {
    heading: {
      highlightedText: 'Three ways ',
      heading1: 'to start',
      heading2: 'creating videos',
    },
    textToVideo: {
      title: {
        heading1: 'Convert text to video',
      },
      details: {
        highlightedText: '50+ AI powered themes ',
        plainText:
          'to choose from, created to convert text-based content to videos in just 3 simple steps.',
      },
      CTAText: 'Get started - it’s free!',
      linkText: 'Explore themes',
      linkURL: `${baseHost}/workflow/storyteller-templates`,
      media: {
        desktop: {
          videoUrl: `${videoHost}/TextToVideo.mp4`,
          poster: {
            defaultURL: `${posterImagesHost}/TexttoVideo.png`,
            webpURL: `${posterImagesHost}/TexttoVideo.webp`,
          },
          videoTimeline: [
            { start: 0, end: 3, name: 'Pick a theme 🍒' },
            {
              start: 3.01,
              end: 6.9,
              name: 'Add a headline & text. Our AI will do it’s magic 🔮',
            },
            {
              start: 7.0,
              end: 11,
              name: 'Edit music or scenes & the video is ready! 🚀',
            },
          ],
        },
        mobile: {
          videoUrl: `${videoHost}/TextToVideoMobile2x.mp4`,
          poster: {
            defaultURL: `${posterImagesHost}/TexttoVideoMobile.png`,
            webpURL: `${posterImagesHost}/TexttoVideoMobile.webp`,
          },
          videoTimeline: [
            { start: 0, end: 3, name: 'Pick a theme 🍒' },
            {
              start: 3.01,
              end: 6.9,
              name: 'Add a headline & text. Our AI will do it’s magic 🔮',
            },
            {
              start: 7.0,
              end: 11,
              name: 'Edit music or scenes & the video is ready! 🚀',
            },
          ],
        },
      },
    },
    proEditor: {
      title: {
        heading1: 'Start with blank canvas',
        heading2: '',
      },
      details: {
        highlightedText: 'Unleash the full potential ',
        plainText:
          'of InVideo editor packed with advanced editing features for the pro in you.',
      },
      CTAText: 'Get started - it’s free!',
      linkText: 'Go to editor',
      linkURL: `${baseHost}/templates/blank`,
      media: {
        url: `${assetsHost}/ProEditor.png`,
        webpURL: `${assetsHost}/ProEditor2x.webp`,
        alt: 'image',
      },
    },
    templatesSlider: {
      title: {
        heading1: 'Start with templates',
      },
      details: {
        highlightedText: 'Quickly create powerful videos',
        plainText:
          ' for ads, promos, YouTube, intros and more from our library of 5000+ customizable templates.',
      },
      CTAText: 'Get started - it’s free!',
      linkText: 'Explore all templates',
      linkURL: `${baseHost}/workflow/marketing-templates`,
      templates: [
        {
          videoUrl:
            'https://s3.ap-south-1.amazonaws.com/invideo-block-assets/Template_Block_Assets/Template_sample_clips/one_click_preview/production/Fitness_Poster_production_story_12399_16_9_1595949455114_SD4.mp4',
          poster:
            'https://s3.ap-south-1.amazonaws.com/asset-snapshot/invideo-block-assets/Template_Block_Assets/Template_sample_clips/one_click_preview/production/Fitness_Poster_production_story_12399_16_9_1595949455114_SD4.jpg',
          templateLink: '#',
          templateName: '5 Things To Do This Summer',
        },
        {
          videoUrl:
            'https://d360zdw5tkn3j9.cloudfront.net/Template_Block_Assets/landing-page/homepage-desk-video.mp4',
          poster:
            'https://d360zdw5tkn3j9.cloudfront.net/Template_Block_Assets/landing-page/v3_video_montage-small.jpg',
          templateLink: '#',
          templateName: '90s Vlog & Podcast',
        },
        {
          videoUrl:
            'https://d360zdw5tkn3j9.cloudfront.net/Template_Block_Assets/landing-page/homepage-desk-video.mp4',
          poster:
            'https://d360zdw5tkn3j9.cloudfront.net/Template_Block_Assets/landing-page/v3_video_montage-small.jpg',
          templateLink: '#',
          templateName: 'Summer Fashion Promo',
        },
        {
          videoUrl:
            'https://d360zdw5tkn3j9.cloudfront.net/Template_Block_Assets/landing-page/homepage-desk-video.mp4',
          poster:
            'https://d360zdw5tkn3j9.cloudfront.net/Template_Block_Assets/landing-page/v3_video_montage-small.jpg',
          templateLink: '#',
          templateName: 'Modern Fashion Lookbook for...',
        },
        {
          videoUrl:
            'https://d360zdw5tkn3j9.cloudfront.net/Template_Block_Assets/landing-page/homepage-desk-video.mp4',
          poster:
            'https://d360zdw5tkn3j9.cloudfront.net/Template_Block_Assets/landing-page/v3_video_montage-small.jpg',
          templateLink: '#',
          templateName: 'History of Coffee',
        },
        {
          videoUrl:
            'https://d360zdw5tkn3j9.cloudfront.net/Template_Block_Assets/landing-page/homepage-desk-video.mp4',
          poster:
            'https://d360zdw5tkn3j9.cloudfront.net/Template_Block_Assets/landing-page/v3_video_montage-small.jpg',
          templateLink: '#',
          templateName: 'History of Coffee',
        },
      ],
    },
  },
  quickTools: {
    heading: {
      heading1: 'Quick tools ‒',
      heading2: 'for ',
      highlightedText: 'simple video edits',
    },
    tools: {
      compress: {
        title: 'Compress',
        image: {
          url: `${assetsHost}/compress.png`,
          alt: 'Compress Video',
        },
        detail: 'Reduce video to a desired file size or a quality level.',
        CTAText: 'Start compressing',
      },
      crop: {
        title: 'Crop',
        image: {
          url: `${assetsHost}/crop.png`,
          alt: 'Crop Video',
        },
        detail: 'Change the video frame to the desired area.',
        CTAText: 'Start cropping',
      },
      trim: {
        title: 'Trim',
        image: {
          url: `${assetsHost}/trim.png`,
          alt: 'Trim Video',
        },
        detail: 'Shorten your video by removing intro or outro parts.',
        CTAText: 'Start trimming',
      },
    },
    bottomText: 'Free & online - no downloads required',
    CTAText: 'See all tools',
  },
};

export const WORKFLOW_TEMPLATE_IDS = [
  19668, 12939, 17502, 17340, 16624, 19950, 16861, 16833, 13422, 11266, 19079,
  18926, 17516, 14398,
];

export const COOKIE_KEYS = {
  EXPERIMENT: 'ivwlp',
  USER_STATE: 'user-state',
  HOMEPAGE_VARIANT: 'hpvariant',
  LOGGED_IN_HOMEPAGE_EXPERIMENT: 'iv-loggedIn-homepage-experiment',
};
